<template>
  <div>
    <h1 class="subtitle-1 grey--text">Устройства</h1>

    <v-container class="mb-5">
      <HardwareCard v-for="hardware in hardwareList" :key="hardware.id" :hardware="hardware" />
    </v-container>

    <template v-if="page != 1">
      <router-link :to="{ name: 'hardware-list', query: { page: page - 1 } }" rel="prev">Prev Page</router-link>
      <template v-if="hasNextPage"> | </template>
    </template>
    <router-link v-if="hasNextPage" :to="{ name: 'hardware-list', query: { page: page + 1 } }" rel="next"> Next Page</router-link>
  </div>
</template>

<script>
import HardwareCard from '@/components/HardwareCard'
import { mapState } from 'vuex'
import store from '@/store/index'

function getPageHardware(routeTo, next) {
  const currentPage = parseInt(routeTo.query.page || 1)
  store
    .dispatch('hardware/fetchPageHardware', {
      page: currentPage
    })
    .then(() => {
      routeTo.params.page = currentPage
      next()
    })
}

export default {
  props: {
    page: {
      type: Number,
      required: true
    }
  },
  components: {
    HardwareCard
  },
  beforeRouteEnter(routeTo, routeFrom, next) {
    getPageHardware(routeTo, next)
  },
  beforeRouteUpdate(routeTo, routeFrom, next) {
    getPageHardware(routeTo, next)
  },
  computed: {
    hasNextPage() {
      return this.hardwareCount > this.page * this.perPage
    },
    ...mapState('hardware', ['hardwareCount', 'hardwareList'])
  }
}
</script>
