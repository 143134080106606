<template>
  <v-card flat class="px-3">
    <v-row row wrap>
      <v-col md="3">
        <div class="caption grey--text">Название</div>
        <div>{{ hardware.model.title }}</div>
      </v-col>
      <v-col md="2">
        <div class="caption grey--text">Серийный номер</div>
        <div>{{ hardware.serialNumber }}</div>
      </v-col>
      <v-col md="3">
        <div class="caption grey--text">Производитель</div>
        <div>{{ hardware.model.vendor.title }}</div>
      </v-col>
      <v-col md="2">
        <div class="caption grey--text">Категория</div>
        <div>{{ hardware.model.category.title }}</div>
      </v-col>
      <v-col md="2">
        <div class="caption grey--text">Время/дата</div>
        <div>{{ hardware.updatedAt | moment('YYYY-MM-DD HH:mm:ss') }}</div>
      </v-col>
    </v-row>
    <v-row cols="12">
      <v-divider></v-divider>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    hardware: Object
  }
}
</script>
